@import '~antd/dist/antd.less';
@appGlobalTheme: 'light';
body {
	margin: 0;
	padding: 0;
	font-family: 'Capsule Sans Text', system-ui, -apple-system, BlinkMacSystemFont,
		'Segoe UI', Helvetica, Arial, sans-serif;
	// -webkit-font-smoothing: antialiased;
	// -moz-osx-font-smoothing: grayscale;
	/* guard for attribute */
	& when (@appGlobalTheme = 'dark') {
		// // dark appGlobalTheme
		color: #ffffffd9;
		background-color: #000 !important;
	}
	& when not (@appGlobalTheme = 'dark') {
		color: #424242;
		background-color: #f8f8f8 !important;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
*,
*:before,
*:after {
	box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	/* guard for attribute */
	& when (@appGlobalTheme = 'dark') {
		// // dark appGlobalTheme
		-webkit-box-shadow: 0 0 0px 1000px #121212 inset !important;
		-webkit-text-fill-color: #ffffffd9;
	}
	& when not (@appGlobalTheme = 'dark') {
		-webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
		-webkit-text-fill-color: #424242;
	}
}

::-moz-selection {
	& when (@appGlobalTheme = 'dark') {
		color: #424242;
		background: #ffffff;
	}
	& when not (@appGlobalTheme = 'dark') {
		color: #ffffff;
		background: #121212;
	}
}

::selection {
	& when (@appGlobalTheme = 'dark') {
		color: #424242;
		background: #ffffff;
	}
	& when not (@appGlobalTheme = 'dark') {
		color: #ffffff;
		background: #121212;
	}
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
   supported by Chrome, Edge, Opera and Firefox */
}

.connected-account .ant-modal-body {
	padding: 0em;
}

.connected-account .ant-form {
	padding-top: 0.25em;
}

